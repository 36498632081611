
import MSubmissionsSection from "@/components/MSubmissionsSection.vue";
import { Options, Vue } from "vue-class-component";

@Options({
  components: {
    MSubmissionsSection
  }
})
export default class LearningView extends Vue {}
