
import { Options, Vue } from "vue-class-component";
import { ComplementedSubmission } from "@/entities/submission";
import { ComplementedThread } from "@/entities/thread";
import {
  convertToDateFromUnixtime,
  convertToTimeFromUnixtime
} from "@/utils/date";
import MIcon from "@/components/MIcon.vue";
import store from "@/store";
import dayjs from "dayjs";

type SubmissionSummary = {
  id: string;
  submittableFrom: number;
  submittableFromText: string;
  closedAt: number;
  closedAtText: string;
  checkedAt: number;
  status: string;
  name: string;
  displayEndAt: number;
};
type ThreadSummary = {
  id: string;
  submittableFrom: number;
  name: string;
  displayEndAt: number;
};

const sortKeys = [
  "unsubmitted",
  "rejected_unsubmitted",
  "submitted",
  "rejected_submitted",
  "fulfilled"
];

@Options({
  components: {
    MIcon
  },
  props: {}
})
export default class SubmissionsSection extends Vue {
  // 当日中の比較が正しくできるよう、比較対象の時刻に翌日の直前を使う
  standardTime = dayjs().unix();
  get submissionsInitialized(): boolean {
    return store.state.submissionsInitialized;
  }
  get submissionsError(): string {
    return store.state.submissionsError;
  }
  get threadsInitialized(): boolean {
    return store.state.threadsInitialized;
  }
  get threadsError(): string {
    return store.state.threadsError;
  }
  get submissionSummaries(): SubmissionSummary[] {
    return (
      store.state.submissions
        .map((s: ComplementedSubmission) => ({
          id: s.ref.id,
          submittableFrom: s.data.submittableFrom,
          submittableFromText: convertToDateFromUnixtime(
            s.data.submittableFrom
          ),
          closedAt: s.data.closedAt,
          closedAtText: `${convertToDateFromUnixtime(
            s.data.closedAt
          )} ${convertToTimeFromUnixtime(s.data.closedAt)}`,
          checkedAt: s.data.checkedAt,
          status: s.data.status,
          name: s.submissionMasterData?.name ?? "",
          displayEndAt: s.data.displayEndAt ?? 0
        }))
        // 表示終了日が0である(undefinedの場合は0に変換) || 表示終了日が現在の日付よりも大きい場合は true
        .filter(a => a.displayEndAt === 0 || a.displayEndAt > this.standardTime)
        .sort(
          (a, b) =>
            // ステータス
            sortKeys.indexOf(a.status) - sortKeys.indexOf(b.status) ||
            // 締切日昇順
            a.closedAt - b.closedAt
        )
    );
  }
  get threadSummaries(): ThreadSummary[] {
    return (
      store.state.threads
        .map((s: ComplementedThread) => ({
          id: s.ref.id,
          submittableFrom: s.data.submittableFrom,
          name: s.threadMasterData?.name ?? "",
          displayEndAt: s.data.displayEndAt ?? 0
        }))
        // 表示終了日が0である(undefinedの場合は0に変換) || 表示終了日が現在の日付よりも大きい場合は true
        .filter(a => a.displayEndAt === 0 || a.displayEndAt > this.standardTime)
        .sort((a, b) => a.displayEndAt - b.displayEndAt)
    );
  }
}
