<template>
  <main class="container mx-auto flex flex-col items-center mt-6 px-4">
    <m-submissions-section />
  </main>
</template>

<script lang="ts">
import MSubmissionsSection from "@/components/MSubmissionsSection.vue";
import { Options, Vue } from "vue-class-component";

@Options({
  components: {
    MSubmissionsSection
  }
})
export default class LearningView extends Vue {}
</script>
