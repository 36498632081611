<template>
  <section class="flex flex-col w-full">
    <h2 class="text-lg font-bold mb-4">提出物</h2>
    <div
      v-if="!submissionsInitialized || !threadsInitialized"
      class="w-full p-3 rounded-md bg-white text-sm font-normal text-gray-500"
    >
      提出物を取得中です
    </div>
    <div
      v-if="submissionsInitialized && submissionsError"
      class="w-full p-3 rounded-md bg-white text-sm font-normal text-gray-500"
    >
      {{ submissionsError }}
    </div>
    <div
      v-if="threadsInitialized && threadsError"
      class="w-full p-3 rounded-md bg-white text-sm font-normal text-gray-500"
    >
      {{ threadsError }}
    </div>
    <div
      v-if="
        submissionsInitialized &&
          !submissionsError &&
          submissionSummaries.length === 0 &&
          threadsInitialized &&
          !threadsError &&
          threadSummaries.length === 0
      "
      class="w-full p-3 rounded-md bg-white text-sm font-normal text-gray-500"
    >
      現在提出する課題はありません
    </div>
    <div class="flex flex-col">
      <router-link
        v-for="s in submissionSummaries"
        :key="s.id"
        :to="`/submission/${s.id}/`"
        class="my-1"
      >
        <article class="px-3 py-2 flex flex-col bg-white rounded-md">
          <p class="font-medium">
            {{ s.name }}
          </p>
          <p class="text-sm font-medium text-gray-500">
            {{ s.submittableFromText }} 〜 {{ s.closedAtText }}
          </p>
          <div
            v-if="s.status === 'submitted' || s.status === 'rejected_submitted'"
            class="flex items-center mt-2 text-secondary-500"
          >
            <m-icon type="check" width="4" size="6" class="mr-2" />
            <span class="text-sm font-medium text-gray-500">
              提出しました
            </span>
          </div>
          <div
            v-else-if="s.status === 'rejected_unsubmitted'"
            class="flex items-center mt-2 text-red-400"
          >
            <m-icon type="refresh" width="2" size="6" class="mr-2" />
            <span class="text-sm font-medium"> 再提出の指示が出ました </span>
          </div>
          <div
            v-else-if="s.status === 'fulfilled'"
            class="flex items-center mt-2 text-secondary-500"
          >
            <m-icon type="check" width="4" size="6" class="mr-2" />
            <span class="text-sm font-medium"> 先生が確認しました </span>
          </div>
          <div
            v-else-if="s.closedAt < standardTime"
            :data-closed-at="s.closedAt"
            class="flex items-center mt-2 text-yellow-300"
          >
            <m-icon type="warning" class="mr-2" />
            <span class="text-sm font-medium text-gray-500">
              期限が過ぎています
            </span>
          </div>
        </article>
      </router-link>
    </div>
    <div class="flex flex-col">
      <router-link
        v-for="s in threadSummaries"
        :key="s.id"
        :to="`/thread/${s.id}/`"
        class="my-1"
      >
        <article class="px-3 py-2 flex flex-col bg-white rounded-md">
          <p class="font-medium">
            {{ s.name }}
          </p>
        </article>
      </router-link>
    </div>
  </section>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { ComplementedSubmission } from "@/entities/submission";
import { ComplementedThread } from "@/entities/thread";
import {
  convertToDateFromUnixtime,
  convertToTimeFromUnixtime
} from "@/utils/date";
import MIcon from "@/components/MIcon.vue";
import store from "@/store";
import dayjs from "dayjs";

type SubmissionSummary = {
  id: string;
  submittableFrom: number;
  submittableFromText: string;
  closedAt: number;
  closedAtText: string;
  checkedAt: number;
  status: string;
  name: string;
  displayEndAt: number;
};
type ThreadSummary = {
  id: string;
  submittableFrom: number;
  name: string;
  displayEndAt: number;
};

const sortKeys = [
  "unsubmitted",
  "rejected_unsubmitted",
  "submitted",
  "rejected_submitted",
  "fulfilled"
];

@Options({
  components: {
    MIcon
  },
  props: {}
})
export default class SubmissionsSection extends Vue {
  // 当日中の比較が正しくできるよう、比較対象の時刻に翌日の直前を使う
  standardTime = dayjs().unix();
  get submissionsInitialized(): boolean {
    return store.state.submissionsInitialized;
  }
  get submissionsError(): string {
    return store.state.submissionsError;
  }
  get threadsInitialized(): boolean {
    return store.state.threadsInitialized;
  }
  get threadsError(): string {
    return store.state.threadsError;
  }
  get submissionSummaries(): SubmissionSummary[] {
    return (
      store.state.submissions
        .map((s: ComplementedSubmission) => ({
          id: s.ref.id,
          submittableFrom: s.data.submittableFrom,
          submittableFromText: convertToDateFromUnixtime(
            s.data.submittableFrom
          ),
          closedAt: s.data.closedAt,
          closedAtText: `${convertToDateFromUnixtime(
            s.data.closedAt
          )} ${convertToTimeFromUnixtime(s.data.closedAt)}`,
          checkedAt: s.data.checkedAt,
          status: s.data.status,
          name: s.submissionMasterData?.name ?? "",
          displayEndAt: s.data.displayEndAt ?? 0
        }))
        // 表示終了日が0である(undefinedの場合は0に変換) || 表示終了日が現在の日付よりも大きい場合は true
        .filter(a => a.displayEndAt === 0 || a.displayEndAt > this.standardTime)
        .sort(
          (a, b) =>
            // ステータス
            sortKeys.indexOf(a.status) - sortKeys.indexOf(b.status) ||
            // 締切日昇順
            a.closedAt - b.closedAt
        )
    );
  }
  get threadSummaries(): ThreadSummary[] {
    return (
      store.state.threads
        .map((s: ComplementedThread) => ({
          id: s.ref.id,
          submittableFrom: s.data.submittableFrom,
          name: s.threadMasterData?.name ?? "",
          displayEndAt: s.data.displayEndAt ?? 0
        }))
        // 表示終了日が0である(undefinedの場合は0に変換) || 表示終了日が現在の日付よりも大きい場合は true
        .filter(a => a.displayEndAt === 0 || a.displayEndAt > this.standardTime)
        .sort((a, b) => a.displayEndAt - b.displayEndAt)
    );
  }
}
</script>
